import React from 'react';
import TabHeader from '../TabSelector/TabHeader';
import './skillStudyMaterialTable.scss';

const studyMaterials = [
  { title: 'Basics of SQL', resource: 'View Resource' },
  { title: 'Session Recording - 12/10/2024', resource: 'View Resource' },
  { title: 'Introduction to Apache', resource: 'View Resource' },
  { title: 'Basics of SQL', resource: 'View Resource' },
  { title: 'Basics of SQL', resource: 'View Resource' },
  { title: 'Practice Quiz - 2', resource: 'View Resource' },
  // Add more items as needed
];

// Helper function to split data into two columns
function splitIntoTwoColumns(data) {
  const middleIndex = Math.ceil(data.length / 2);
  return [data.slice(0, middleIndex), data.slice(middleIndex)];
}

function SkillStudyMaterialTable() {
  const [leftColumn, rightColumn] = splitIntoTwoColumns(studyMaterials);

  return (
    <div className='skill-study-material-container'>
      <TabHeader title="Study Material" />
      <div className='skill-material-list'>
        {[leftColumn, rightColumn].map((column, columnIndex) => (
          <div className='skill-material-column' key={columnIndex}>
            {column.map((material, index) => (
              <div className='skill-material-item' key={index}>
                <div className='skill-title'>{material.title}</div>
                <div className='skill-resource'>{material.resource}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SkillStudyMaterialTable;
