import React from 'react';
import './radarChart.scss';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer
} from 'recharts';

const CustomTick = ({ payload, x, y, textAnchor }) => {
  return (
    <text x={x} y={y} textAnchor={textAnchor} className='custom-tick' style={{fill: 'var(--primary-text-color)'}}>
      {payload.value}
    </text>
  );
};
const dataAttempt1 = [
  { subject: 'SQL', A: 10, B: 50, fullMark: 100 },
  { subject: 'MongoDB', A: 40, B: 100, fullMark: 100 },
  { subject: 'Apache Cassandra', A: 70, B: 100, fullMark: 100 },
  { subject: 'Python', A: 40, B: 100, fullMark: 100 },
  { subject: 'Hadoop', A: 70, B: 100, fullMark: 100 },
  { subject: 'Azure', A: 50, B: 100, fullMark: 100 },
  { subject: 'Apache Spark', A: 50, B: 100, fullMark: 100 },
  { subject: 'EDA', A: 70, B: 100, fullMark: 100 },
  { subject: 'Spark Streaming', A: 60, B: 100, fullMark: 100 },
  { subject: 'PySpark', A: 70, B: 100, fullMark: 100 },
  { subject: 'PowerBI', A: 80, B: 100, fullMark: 100 },
  { subject: 'Excel', A: 50, B: 100, fullMark: 100 },

];

const dataAttempt2 = [
  { subject: 'SQL', A: 60, B: 80, fullMark: 100 },
  { subject: 'MongoDB', A: 30, B: 90, fullMark: 100 },
  { subject: 'Apache Cassandra', A: 50, B: 70, fullMark: 100 },
  { subject: 'Python', A: 60, B: 90, fullMark: 100 },
  { subject: 'Hadoop', A: 80, B: 100, fullMark: 100 },
  { subject: 'Azure', A: 70, B: 85, fullMark: 100 },
  { subject: 'Apache Spark', A: 50, B: 100, fullMark: 100 },
  { subject: 'EDA', A: 70, B: 100, fullMark: 100 },
  { subject: 'Spark Streaming', A: 50, B: 100, fullMark: 100 },
  { subject: 'PySpark', A: 50, B: 100, fullMark: 100 },
  { subject: 'PowerBI', A: 50, B: 100, fullMark: 100 },
  { subject: 'Excel', A: 50, B: 100, fullMark: 100 },
];

function CustomRadarChart({ isFirstActive = true, isSecondActive = false, attempt }) {
  const data = attempt === 1 ? dataAttempt1 : dataAttempt2;
  const color = attempt === 1 ? 'var(--accent-secondary-purple-color)' : 'var(--accent-secondary-orange-color)';
  return (
    <div className='custom-radar-chart'>
      <ResponsiveContainer width='140%' height={400}>
        <RadarChart cx='50%' cy='50%' outerRadius='77%' data={data}>
          <PolarGrid
            gridType='circle'
            stroke='var(--quinary-text-color)'
            polarRadius={[
              10, 30, 50, 70, 90, 110, 130, 150
            ]}
          />
          <PolarAngleAxis dataKey='subject' tick={<CustomTick />} tickSize={20}/>
          <PolarRadiusAxis domain={[0, 100]} tick={false} axisLine={false} />
          {isFirstActive && (
            <Radar
              name='Student A'
              dataKey='A'
              stroke={color}
              strokeWidth={2.5}
              fill={color}
              fillOpacity={0.3}
              dot={true}
            />
          )}
          {/* <Radar
            name='Student B'
            dataKey='B'
            stroke='var(--accent-secondary-orange-color)'
            strokeWidth={2.5}
            fill='var(--accent-secondary-orange-color)'
            fillOpacity={0.3}
            dot={true}
          /> */}
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomRadarChart;
