// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-progress {
  position: relative;
}
.circle-progress svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}
.circle-progress .arc-bg, .circle-progress .arc-fill {
  transform-origin: center;
  stroke-linecap: round;
  transition: stroke-dasharray 0.4s ease;
}
.circle-progress .circle-text {
  fill: #6e7780;
  font-size: 20px;
  font-weight: 600;
}
.circle-progress__label {
  color: var(--Color-Text-Tertiary, #5D6C83);
  text-align: center;
  font-family: "Semi Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.circle-progress__sub-label {
  text-align: center;
  font-family: "Medium Sans";
  font-style: normal;
  color: var(--Color-Text-Quartinary, #7C8BA2);
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
  font-size: 14px;
}
.circle-progress__label-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/Temp/CircleProgress.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AACJ;AAEE;EACE,wBAAA;EACA,qBAAA;EACA,sCAAA;AAAJ;AAGE;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,0CAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,0BAAA;EACA,kBAAA;EACA,4CAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAHJ;AAME;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAJJ","sourcesContent":[".circle-progress {\n  position: relative;\n\n  svg {\n    width: 100%;\n    height: 100%;\n    transform: rotate(-90deg);\n  }\n\n  .arc-bg, .arc-fill {\n    transform-origin: center;\n    stroke-linecap: round;\n    transition: stroke-dasharray 0.4s ease;\n  }\n\n  .circle-text {\n    fill: #6e7780;\n    font-size: 20px;\n    font-weight: 600;\n  }\n\n  &__label {\n    color: var(--Color-Text-Tertiary, #5D6C83);\n    text-align: center;\n    font-family: \"Semi Sans\";\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 100%;\n  }\n\n  &__sub-label {\n    text-align: center;\n    font-family: \"Medium Sans\";\n    font-style: normal;\n    color: var(--Color-Text-Quartinary, #7C8BA2);\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 125%;\n    font-size: 14px;\n  }\n\n  &__label-box {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
