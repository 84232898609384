import React, { useState } from 'react';
import RadarChart from '../RadarChart/RadarChart';
import CircleProgress from './CircleProgress';
import CustomSkillBarChart from '../../@component/CustomSkillBarChart/CustomSkillBarChart';
import StatusBox from '../../@component/StatusBox/StatusBox';
import TabHeader from '../TabSelector/TabHeader';
import SkillsCard from '../SkillsCard/SkillsCard';
import SkillStudyMaterialTable from '../SkillStudyMaterial/SkillStudyMaterialTable';

function Temp() {
  const data = [
    {
      numerator: 30,
      denominator: 50,
      color: 'green',
      backgroundColor: 'lightgreen',
    },
    {
      numerator: 23,
      denominator: 50,
      color: 'blue',
      backgroundColor: 'lightblue'
    },
  ];
  const skillsData = [
    { skill: 'Python', primaryPercentage: 100, secondaryPercentage: 70 },
    { skill: 'SQL', primaryPercentage: 80, secondaryPercentage: 75 },
    { skill: 'Azure', primaryPercentage: 80, secondaryPercentage: 60 },
  ];
  
  return (
    <>
     <div style={{ display: 'flex', gap: '8px', padding: "20px" }}>
    <StatusBox label="Attempt 1" percentage={72} isActive={true} iconFill={true} textColor="#9b51e0" />
    <StatusBox label="Attempt 2" percentage={80} isActive={false} iconFill={false} iconColor='#ff9900' textColor="#ff9900" />
    <StatusBox label="Top Performer" percentage={95} isActive={false} iconFill={false} iconColor="#28a745" textColor="#28a745" hasBorder={false} />
    <StatusBox label="Your Best Score" percentage={80} isActive={false} iconFill={false} iconColor='#000' textColor="#000" hasBorder={false} />
  </div>
   <div><SkillsCard /></div>
   <div><SkillStudyMaterialTable /></div>

      <RadarChart />
      <CircleProgress data={data} />
    </>
  );
}

export default Temp;
