import { differenceInDays, format } from 'date-fns';
import { toast } from 'react-toastify';
import resourceEmptyImage from '../asset/images/resource_notfound.svg';
import unreadNotFound from '../asset/images/unreadTab_notfound.svg';
import bookmarkNotFound from '../asset/images/bookmark_notfound.svg';
import gesturingGrade from '../asset/images/gesturingGrade.png';
import checkCircleProgress from '../asset/images/check-circle-progress.svg';
import gesture_ok from '../asset/icons/gesture_ok.png';
import { ROUTES } from '../config/routes';
import { backendBaseUrl } from '../api/config';
import pdfIcon from '../asset/images/pdf-icon.png';
import zipIcon from '../asset/images/zip-icon.png';
import imageIcon from '../asset/images/image-icon.png';
import docIcon from '../asset/images/doc-icon.png';
import videoIcon from '../asset/images/video-icon.png';
import otherIcon from '../asset/images/other-icon.png';
import pdfIconDark from '../asset/images/pdf-icon-dark.png';
import zipIconDark from '../asset/images/zip-icon-dark.png';
import imageIconDark from '../asset/images/image-icon-dark.png';
import docIconDark from '../asset/images/doc-icon-dark.png';
import videoIconDark from '../asset/images/video-icon-dark.png';
import otherIconDark from '../asset/images/other-icon-dark.png';
import { HOME_SECTIONS } from '../config/cleverTapEvents';
import SuccessToastMessage from '../@common/SuccessToast/SuccessToast';

const constantLogin = {
  emptyMessage: 'Please enter a valid mobile number or email address.',
  emptyMessageOtp: 'Please enter a valid OTP.'
};
// Think we have /:progamId/:activity/:quizId
function handleStringToNumber(input) {
  if (input === null) {
    return 0;
  }
  const number = Number(input);
  return isNaN(number) ? 0 : number;
}
const LiveIcon = ({ circleFill = '#EF5054', playFill = '#FDFDFD' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
  >
    <circle cx='16.5' cy='16' r='12' fill={circleFill} />
    <path
      d='M13.3298 12.6884V19.314C13.3298 19.8255 13.5507 20.1975 13.9924 20.43C14.4341 20.6624 14.8642 20.6392 15.2827 20.3602L20.4786 17.0822C20.8738 16.8265 21.0714 16.4662 21.0714 16.0012C21.0714 15.5362 20.8738 15.1759 20.4786 14.9202L15.2827 11.6422C14.8642 11.3632 14.4341 11.34 13.9924 11.5724C13.5507 11.8049 13.3298 12.1769 13.3298 12.6884Z'
      fill={playFill}
    />
  </svg>
);

const constantSignUp = {
  emptyMessage: 'Please enter a valid mobile number.'
};

//format: Monday, 2 September 2021
const DATE_WEEK_STRING_FORMAT = 'EEEE, do MMMM yyyy';
const DATE_WEEKTIME_STRING_FORMAT = 'EEEE, do MMMM yyyy HH:mm a';
const ACCESS_TYPE = {
  Premium: 'Premium',
  Free: 'Free'
};
const formatDate = (dateString, outputFormat) => {
  const date = new Date(dateString);
  const formattedDate = format(date, outputFormat);
  return formattedDate;
};
const courseStatusVal = {
  Recommended: 'Recommended',
  Ongoing: 'Ongoing',
  Locked: 'Locked',
  Unlocked: 'Unlocked',
  Completed: 'Completed'
};
// To get the status text and icon for the course module.
// status:number
const courseStatus = (status) => {
  switch (status) {
    case courseStatusVal.Recommended:
      return {
        icon: <span className='material-symbols-outlined'>hotel_class</span>
      };
    case courseStatusVal.Ongoing:
      return {
        icon: <span className='material-symbols-outlined'>play_arrow</span>
      };
    case courseStatusVal.Locked:
      return {
        disable: true,
        icon: <span className='material-symbols-outlined'>lock</span>
      };
    case courseStatusVal.Unlocked:
      return {
        icon: <span className='material-symbols-outlined'>lock_open</span>
      };
    case courseStatusVal.Completed:
      return {
        icon: <span className='material-symbols-outlined'>done</span>
      };
    default:
      return false;
  }
};
const handleRedirection = (
  type,
  val,
  navigate,
  programId = 0,
  cmid = false
) => {
  const cmId = cmid ? cmid : val?.cmid || val?.details?.cmid;
  const url =
    val?.url ||
    val?.activityurl ||
    val?.sectionurl ||
    backendBaseUrl + '/mod/' + type + '/view.php' + '?id=' + cmId;

  switch (type) {
    case 'refer':
      navigate(ROUTES.REFER_N_EARN);
      return null;

    case 'quiz':
    case 'url':
    case 'hvp':
    case 'page':
    case 'folder':
    case 'resource':
    case 'videofile':
    case 'scorm':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':programid', programId)
          .replace(':type', type)
          .replace(':id', cmId)
      );
      return null;
    case 'feedback':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':programid', programId)
          .replace(':type', 'feedback')
          .replace(':id', cmId)
      );
      return null;
    case 'zoom':
    case 'zoomduplicate':
      const currentSeconds = Math.floor(Date.now() / 1000);
      // if (
      //   handleStringToNumber(val?.timestart) - currentSeconds <= 900 &&
      //   handleStringToNumber(val?.timestart) - currentSeconds >= 0
      // ) {
      //   navigate(ROUTES.ZOOM.replace(':id', cmId));
      // } else {
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':programid', programId)
          .replace(':type', type)
          .replace(':id', cmId)
      );
      // }
      return null;
    case 'page':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':type', 'page')
          .replace(':id', cmId)
          .replace(':programid', programId)
      );
      return null;
    case 'folder':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':type', 'folder')
          .replace(':id', cmId)
          .replace(':programid', programId)
      );
      return null;
    case 'forum':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':type', 'forum')
          .replace(':id', cmId)
          .replace(':programid', programId)
      );
      return null;
    case 'resource':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':type', 'resource')
          .replace(':id', cmId)
          .replace(':programid', programId)
      );
      return null;
    case 'assign':
      navigate(
        ROUTES.PAGE_ACTIVITY.replace(':type', 'assign')
          .replace(':id', cmId)
          .replace(':programid', programId)
      );
      return null;
    default:
      window.open(
        url + '&token=' + btoa(localStorage.getItem('token')),
        '_blank'
      );
      return null;
  }
};
const activityIcon = (val, otherColor1 = '') => {
  const otherColor = otherColor1;
  switch (val) {
    case 'quiz':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            contact_support
          </span>
        ),
        color: 'var(--accent-tertiary-orange-color)',
        title: 'Quiz'
      };

    case 'assign':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            assignment
          </span>
        ),
        color: 'var(--accent-tertiary-purple-color)',
        title: 'Assignment'
      };

    case 'zoom':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            live_tv
          </span>
        ),
        color: 'var(--accent-tertiary-green-color)',
        title: 'Session',
        activitytitle: 'Zoom Session'
      };
    case 'zoomduplicate':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            live_tv
          </span>
        ),
        color: 'var(--accent-tertiary-green-color)',
        title: 'Session'
      };

    case 'videofile':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            play_circle
          </span>
        ),
        color: otherColor,
        title: 'Video'
      };

    case 'folder':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            folder_open
          </span>
        ),
        color: otherColor,
        title: 'Folder'
      };

    case 'url':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            link
          </span>
        ),
        color: otherColor,
        title: 'Url'
      };

    case 'forum':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            forum
          </span>
        ),
        color: otherColor,
        title: 'Forum'
      };

    case 'scorm':
      return {
        icon: (
          <span
            className='material-symbols-rounded'
            style={{ color: 'var(--primary-text-color)' }}
          >
            co_present
          </span>
        ),
        color: otherColor,
        title: 'Scorm'
      };

    case 'hvp':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            award_star
          </span>
        ),
        color: otherColor,
        title: 'Hvp'
      };

    case 'page':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            draft
          </span>
        ),
        color: otherColor,
        title: 'Page'
      };

    case 'resource':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            lab_profile
          </span>
        ),
        color: otherColor,
        title: 'Resource'
      };

    case 'feedback':
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            reviews
          </span>
        ),
        color: 'var(--accent-tertiary-yellow-color)',
        title: 'Feedback'
      };

    default:
      return {
        icon: (
          <span
            className='material-symbols-rounded icon'
            style={{ color: 'var(--primary-text-color)' }}
          >
            web_asset
          </span>
        ),
        title: val,
        color: otherColor
      };
  }
};
const getStatus = (status) => {
  switch (status) {
    case 'due':
    case 'due on':
    case 'live':
      return { title: status, color: '#EF5054' };
    case 'not submitted':
    case 'not attended':
    case 'not available':
      return {
        title: status,
        color: 'var(--quartinary-text-color)'
      };
    default:
      return {
        title: status,
        color: 'var(--accent-primary-dark-green-color)'
      };
  }
};

const PROGRESS_TAB = {
  progress: 1,
  grade: 3
};
const getUrlQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = {};

  // Iterate over each query parameter and add it to the object
  for (let param of searchParams.entries()) {
    const [key, value] = param;
    queryParams[key] = value;
  }

  return queryParams;
};

const searchPopover = [
  { title: 'All', id: 0 },
  { title: 'Classes and Sessions', id: 1 },
  { title: 'Learning Resources', id: 2 },
  { title: 'Assignments', id: 3 },
  { title: 'Quizzes', id: 4 },
  { title: 'Topics', id: 5 }
];
const resourceSidebarNavItem = {
  RESOURCE: {
    key: 'resource',
    name: 'All Resources',
    title: 'No resources found',
    imagePath: resourceEmptyImage,
    icon: <span class='material-symbols-outlined icon'>article</span>
  },
  UNREAD: {
    name: 'Unread',
    key: 'unread',
    title: 'You have read all resources',
    imagePath: unreadNotFound,
    icon: <span class='material-symbols-outlined icon'>wysiwyg</span>
  },
  SAVED: {
    name: 'Saved',
    key: 'saved',
    title: 'Saved resources would appear here',
    imagePath: bookmarkNotFound,
    icon: <span class='material-symbols-outlined icon'>bookmark</span>
  }
};

function calculatePercentage(value, total, fixedStep = 2) {
  value = Number(value);
  total = Number(total);
  if (total === 0) {
    return '--';
  }

  var percentage = (value / total) * 100;

  if (Number.isInteger(percentage)) {
    return percentage.toFixed(0);
  } else {
    // Check if the percentage ends with ".00"
    if (percentage.toFixed(fixedStep).endsWith('.00')) {
      return percentage.toFixed(0);
    } else {
      return percentage.toFixed(fixedStep);
    }
  }
}

const CURRENT_INDUSTRY = [
  'Select an Option',
  'Analytics/KPO/Research',
  'BPO/Call Centre',
  'IT Services & Consulting',
  'Electronic Components/Semiconductors',
  'Electronics Manufacturing',
  'Electronics Manufacturing Services(EMS)',
  'Emerging Technologies',
  '3D Printing',
  'AI/ML',
  'Blockchain',
  'Cloud',
  'Cybersecurity',
  'Drones/Robotics',
  'IOT',
  'Nanotechnology',
  'Hardware & Networking',
  'Internet',
  'E-Commerce',
  'OTT',
  'Software Product',
  'Banking',
  'Financial Services',
  'Asset Management',
  'Broking',
  'FinTech/Payments',
  'Insurance',
  'Investment Banking/Venture Capital/Private Equity',
  'NBFC',
  'Micro Finance NBFC',
  'Education/Training',
  'E-Learning/EdTech',
  'Auto Components',
  'Tyre',
  'Automobile',
  'Automobile Dealers',
  'Electric Vehicle(EV)',
  'Building Material',
  'Cement',
  'Ceramic',
  'Glass',
  'Chemicals',
  'Paints',
  'Defence & Aerospace',
  'Electrical Equipment',
  'Fertilizers/Pesticides/Agro chemicals',
  'Industrial Automation',
  'Industrial Equipment/Machinery',
  'Construcion Equipment',
  'Machine Tools',
  'Iron & Steel',
  'Metals & Mining',
  'Packaging & Containers',
  'Petrochemical/Plastics/Rubber',
  'Pulp & Paper',
  'Aviation',
  'Courier/Logistics',
  'Logistics/Tech',
  'Engineering & Construction',
  'Oil & Gas',
  'Ports & Shipping',
  'Shipbuilding',
  'Power ',
  'Hydro Power ',
  'Nuclear Power',
  'Solar Power ',
  'Wind Power',
  'Railways',
  'Real Estate',
  'Co-working',
  'Urban Transport',
  'Water Treatment/Waste Management',
  'Beauty & Personal Care',
  'Beverage',
  'Brewery/Distillery/Beverage',
  'Consumer Electronics & Appliances',
  'Fitness & Wellness',
  'FMCG',
  'Tobacco',
  'Food Processing',
  'Meat/Poultry',
  'Sugar',
  'Furniture & Furnishing',
  'Gems & Jewellery',
  'Hotels & Restaurants',
  'Leather',
  'Retail',
  'Textile & apparel',
  'Fashion',
  'Handicraft',
  'Home Textile',
  'Technical Textile',
  'Yarn & Fabric',
  'Travel & Tourism',
  'Biotechnology',
  'Clinical Research/Contract Research',
  'Medical Devices & Equipment',
  'Medical Services/Hospital',
  'Diagnostics',
  'Pharmaceutical & Life Sciences',
  'Advertising & Marketing',
  'Digital Marketing',
  'Public Relations',
  'Animation & VFX',
  'Events/Live Entertainment',
  'Film/Music/Entertainment',
  'Gaming',
  'Printing & Publishing',
  'Sports/Leisure & Recreation',
  'Telecom/ISP',
  'TV/Radio',
  'Accounting/Auditing',
  'Architecture/Interior Design',
  'Content Development/Language',
  'Design',
  'Facility Management Services',
  'Law Enforcement/Services',
  'Legal',
  'Management Consulting',
  'Recruitment/Staffing',
  'Agriculture/Forestry/Fishing',
  'Agri-tech',
  'Government/Public Administration',
  'Import & Export',
  'Miscallaneous',
  'NGO/Social Services/Industry Associations',
  'Other'
];
const PREFERRED_CITY_LIST = [
  'Ahmedabad',
  'Bengaluru',
  'Bhopal',
  'Bhubaneshwar',
  'Chandigarh',
  'Chennai',
  'Cochin',
  'Coimbatore',
  'Dehradun',
  'Delhi',
  'Gandhinagar',
  'Ghaziabad',
  'Gurugram',
  'Guwahati',
  'Gwalior',
  'Hyderabad',
  'Indore',
  'Jaipur',
  'Kochi',
  'Kolkata',
  'Madurai',
  'Mohali',
  'Mumbai',
  'Mysore',
  'Nagpur',
  'Nashik',
  'Navi Mumbai',
  'Noida',
  'Patna',
  'Pune',
  'Raipur',
  'Rajkot',
  'Ranchi',
  'Surat',
  'Trivandrum',
  'Vadodara',
  'Vishakhapatnam'
];
const SOCIAL_PROFILE = ['Select an option', 'LinkedIn', 'GitHub', 'Kaggle'];
const SKILLS_PLACEHOLDER = [
  'e.g. Leadership',
  'e.g. SQL',
  'e.g. Corporate Finance'
];
const UG_COURSE_LIST = [
  'B.Tech/B.E.',
  'B.Com',
  'B.Sc',
  'B.A',
  'Diploma',
  'B.Arch',
  'B.B.A/B.M.S',
  'B.Des.',
  'B.Ed',
  'B.EI.Ed',
  'B.P.Ed',
  'B.Pharma',
  'B.U.M.S',
  'BAMS',
  'BCA',
  'BDS',
  'BFA',
  'BHM',
  'BHMCT',
  'BHMS',
  'BVSC',
  'LLB',
  'MBBS',
  'IIM 5-year Integrated Mgmt. Programme',
  'Others'
];
const PG_COURSE_LIST = [
  'MBA/PGDM',
  'M.Tech',
  'MS/M.Sc(Science)',
  'MVA',
  'M.Com',
  'PG Diploma',
  'MA',
  'CA',
  'CS',
  'DM',
  'ICWA(CMA)',
  'Integrated PG',
  'LLM',
  'M.Arch',
  'M.Ch',
  'M.Des',
  'M.Ed',
  'M.Pharma',
  'MCM',
  'MDS',
  'Medical-MS/MD',
  'MFA',
  'MVCS',
  'Others'
];
const handleLastDecimalChange = (e) => {
  let value = e.target.value;
  if (value[value.length - 1] === '.') {
    value = value.substring(0, value.length - 1);
  }
  e.target.value = value;
  return e;
};

function getDaysDiff(startDate, endDate) {
  const diffInDays = differenceInDays(endDate, startDate);

  return diffInDays;
}
const DEFAULT_SOCIAL_PROFILE_SELECT_OPTION = 'Select an option';

const parseQueryParams = (queryString) => {
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {};
  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

const htmlDataParser = (htmlData) => {
  // Create a new HTML document
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlData, 'text/html');

  // Get the text content of the root element
  const textContent = htmlDoc.documentElement.textContent;
  return textContent;
};

const getIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    toast.error('Failed to retrieve IP address.');
  }
};
const parseInteger = (input) => {
  if (input?.length) {
    const result = parseFloat(input);
    return isNaN(result) ? input : result;
  }
  return input;
};
const PER_PAGE = 10;

const progressSectionCardDetails = [
  {
    title: '-',
    subTitle: 'My Grade',
    content: 'Grades are not available at the moment',
    badgeIcon: gesture_ok,
    badgeContent: '',
    icon: gesturingGrade,
    titlePostFix: '%',
    tooltipContent:
      'Your Program Grade till date across all courses where grading is enabled. You must achieve a minimum of 60% grade for program completion',
    recenttooltipContent:
      'Your Program Grade till date across all courses where grading is enabled. You must achieve a minimum of 60% grade for program completion'
  },
  {
    title: '-',
    subTitle: 'Attendance',
    contentAtt: 'Attendance is not available at the moment',
    badgeIcon: '../../asset/icons/check_circle.png',
    badgeContent: '',
    iconAtt: checkCircleProgress,
    titlePostFix: '%',
    tooltipContent:
      'Your attendance for the mandatory lectures and other sessions'
  }
];

const SUPPORTED_PAGE_ACTIVITY = {
  page: {
    title: 'page',
    icon: <span class='material-symbols-outlined'>contract</span>,
    clevertapKey: 'Page'
  },
  feedback: {
    title: 'feedback',
    icon: <span class='material-symbols-outlined'>reviews</span>,
    clevertapKey: 'Feedback'
  },
  resource: {
    title: 'resource',
    icon: <span class='material-symbols-outlined'>file_copy</span>,
    clevertapKey: 'File'
  },
  quiz: {
    title: 'quiz',
    icon: <span class='material-symbols-outlined'>contact_support</span>,
    clevertapKey: 'Quiz'
  },
  videofile: {
    title: 'videofile',
    icon: <span class='material-symbols-outlined'>play_circle</span>,
    clevertapKey: 'Video'
  },
  folder: {
    title: 'folder',
    icon: <span class='material-symbols-outlined'>folder_open</span>,
    clevertapKey: 'Folder'
  },
  zoom: {
    title: 'zoom',
    icon: <span class='material-symbols-outlined'>live_tv</span>,
    clevertapKey: 'Zoom'
  },
  zoom_duplicate: {
    title: 'zoomduplicate',
    icon: <span class='material-symbols-outlined'>live_tv</span>,
    clevertapKey: 'Zoom_Duplicate'
  },
  scorm: {
    title: 'scorm',
    icon: <span class='material-symbols-outlined'>web_asset</span>,
    clevertapKey: 'Scorm'
  },
  hvp: {
    title: 'hvp',
    icon: <span class='material-symbols-outlined'>award_star</span>,
    clevertapKey: 'Hvp'
  },
  url: {
    title: 'URL',
    icon: <span class='material-symbols-outlined'>link</span>,
    clevertapKey: 'URL'
  },
  assign: {
    key: 'assign',
    title: 'assignment',
    icon: <span class='material-symbols-outlined'>assignment</span>,
    clevertapKey: 'Assignment'
  },
  doselect: {
    title: 'doselect',
    icon: <span class='material-symbols-outlined'>contact_support</span>,
    clevertapKey: 'DoSelect_Quiz'
  },
  forum: {
    title: 'forum',
    icon: <span class='material-symbols-outlined'>forum</span>
  }
};
const QUIZ_TYPE = {
  truefalse: 'True False',
  multichoice: 'Mutiple choice (Multiple correct)',
  singlechoice: 'Mutiple choice (Single correct)',
  essay: 'Essay',
  shortanswer: 'Short answer'
};
const QUIZ_ANSWER_STATUS = {
  1: {
    title: 'Your answer is correct',
    key: ''
  },
  2: {
    title: 'Your answer is partially correct'
  },
  0: {
    title: 'Your answer is incorrect'
  },
  '-1': {
    title: ''
  }
};

const getUrlReplace = (fileUrl = '', token) => {
  if (fileUrl?.includes(backendBaseUrl)) {
    return (
      fileUrl.replace('/pluginfile.php', '/webservice/pluginfile.php') +
      '?token=' +
      token
    );
  } else {
    return fileUrl;
  }
};

const getFileType = (file, uploaded = false, token) => {
  const fileTypeParts = !uploaded
    ? file?.type?.split('/')
    : file?.mimetype?.split('/');
  const fileExtension =
    fileTypeParts?.length > 1 ? fileTypeParts[1] : file?.type || file?.mimetype;
  if (fileExtension === 'pdf') {
    return (
      <span class='material-symbols-outlined' style={{ color: '#F40F02' }}>
        picture_as_pdf
      </span>
    );
  } else if (
    ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg+xml'].includes(fileExtension)
  ) {
    return (
      <img
        src={
          file?.fileurl?.length
            ? file?.fileurl.replace(
                '/pluginfile.php',
                '/webservice/pluginfile.php'
              ) +
              `&token=` +
              token
            : URL.createObjectURL(file)
        }
        className='img-img'
      />
    );
  } else if (['mp4', 'avi', 'mkv', 'mov'].includes(fileExtension)) {
    return <span class='material-symbols-outlined'>play_circle</span>;
  } else {
    return <span class='material-symbols-outlined'>description</span>;
  }
};
const data = [
  {
    title: 'Live Sessions',
    key: 'Lectures',
    required: true,
    toolTipContent: `Attendance in ‘live sessions’ is mandatory for program completion`,
    type: 1,
    color: '#FAB300'
  },
  {
    title: 'Doubt Clearing Sessions',
    key: 'doubt',
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 2,
    color: '#5095FC',
    required: false
  },
  {
    title: 'Practice Sessions',
    key: 'practice',
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 1,
    color: '#8816BD',
    required: false
  },
  {
    title: 'Other Sessions',
    key: 'other',
    required: false,
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 2,
    color: '#F8752B'
  }
];
const carreerSession = [
  {
    title: 'Placement & Orientation Sessions',
    key: 'Placement',
    required: true,
    toolTipContent:
      'Attendance in these sessions is mandatory for placement eligibility. You must have 100% attendance in these sessions.',
    type: 1,
    color: '#FAB300'
  },
  {
    title: 'Career Practice Sessions',
    key: 'career_practice',
    toolTipContent: 'These sessions are optional but important for your career',
    type: 2,
    color: '#5095FC',
    required: false
  }
];

const Resume_Approval_Status = {
  0: {
    title: <p>Verification Pending</p>,
    icon: <span class='material-symbols-rounded'>schedule</span>,
    className: 'resume-pending'
  },
  1: {
    title: <p>Verified</p>,
    icon: <span class='material-symbols-outlined filled'>check_circle</span>,
    className: 'resume-success'
  },
  2: {
    title: <p>Rejected</p>,
    icon: <span class='material-symbols-outlined filled'>cancel</span>,
    className: 'resume-reject'
  },
  3: {
    title: <p>Verification Pending</p>,
    icon: <span class='material-symbols-rounded'>schedule</span>,
    className: 'resume-pending'
  }
};

const extractProgramIdFromUrl = (url, routes) => {
  for (const route of Object.values(routes)) {
    const routeRegex = route.replace(/:[^\s/]+/g, '([^/]+)');
    const match = url.match(new RegExp(`^${routeRegex}$`));
    if (match) {
      return match[1]; // Assuming the programid is always the first parameter
    }
  }
  return null;
};

const cleanUrl = (url) => {
  // Remove dynamic segments like /:id, /:programid, etc.
  return url.replace(/\/:[^\/]+/g, '').replace(/\/null/g, '');
};

export const convertUnixTimestampToDate = (unixTimestamp) => {
  const date = new Date((unixTimestamp + 19800) * 1000);
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  // const dayOfWeek = days[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${day} ${month}, ${year}, ${hours}:${minutes} ${ampm}`;
};

export const extensions = {
  applicationExtensions: [
    'zip',
    'json',
    'xml',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'js',
    'pptx'
  ],
  imageExtensions: [
    'png',
    'jpeg',
    'jpg',
    'gif',
    'svg',
    'webp',
    'bmp',
    'tiff',
    'tif'
  ],

  audioExtensions: ['mp3', 'wav', 'ogg', 'weba', 'aac'],
  videoExtensions: ['mp4', 'webm', 'ogv', 'mov', 'avi'],
  textExtensions: ['txt', 'html', 'htm', 'css', 'js', 'csv', 'pdf']
};

export const timeAgo = (unixTimestamp) => {
  const now = Date.now() / 1000;
  const secondsElapsed = now - unixTimestamp;

  const units = [
    { name: 'year', seconds: 60 * 60 * 24 * 365 },
    { name: 'month', seconds: 60 * 60 * 24 * 30 },
    { name: 'day', seconds: 60 * 60 * 24 },
    { name: 'hour', seconds: 60 * 60 },
    { name: 'minute', seconds: 60 },
    { name: 'second', seconds: 1 }
  ];

  for (let unit of units) {
    const interval = Math.floor(secondsElapsed / unit.seconds);
    if (interval >= 1) {
      return `${interval} ${unit.name}${interval !== 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
};

export const hasTimeElapsed = (unixTimestamp, minutes) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const elapsedTime = currentTime - unixTimestamp;
  const elapsedMinutes = elapsedTime / 60;

  return elapsedMinutes >= minutes;
};

export const uploadIcons = (obj) => {
  const theme = localStorage?.getItem('vlearn-theme') || '';
  const extension =
    obj?.filename?.split('.')?.at(-1) || obj?.name?.split('.')?.at(-1);
  if (['zip'].includes(extension)) {
    return theme == 'dark' ? zipIconDark : zipIcon;
  } else if (extensions.applicationExtensions.includes(extension)) {
    return theme == 'dark' ? docIconDark : docIcon;
  } else if (extensions.imageExtensions.includes(extension)) {
    return theme == 'dark' ? imageIconDark : imageIcon;
  } else if (extensions.videoExtensions.includes(extension)) {
    return theme == 'dark' ? videoIconDark : videoIcon;
  } else if (extensions.textExtensions.includes(extension)) {
    return theme == 'dark' ? pdfIconDark : pdfIcon;
  } else {
    return theme == 'dark' ? otherIconDark : otherIcon;
  }
};

export const formatLinks = (obj, token, allowDownload = false) => {
  const extension = obj?.filename?.split('.')?.pop();
  let href = '';
  const tokenParam = `${obj.fileurl.includes('?') ? '&' : '?'}token=`;

  if (extensions.applicationExtensions.includes(extension)) {
    href = `${obj.fileurl}${tokenParam}${token}`;
  } else if (extensions.imageExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}${tokenParam}${btoa(
      token
    )}`;
  } else if (extensions.audioExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}${tokenParam}${btoa(
      token
    )}`;
  } else if (extensions.videoExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}${tokenParam}${btoa(
      token
    )}`;
  } else if (extensions.textExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}${tokenParam}${btoa(
      token
    )}`;
  }

  if (allowDownload) {
    const downloadLink = document.createElement('a');
    downloadLink.href = href;
    downloadLink.download = obj.filename || 'download';
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  return href;
};

export const formatFileSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = bytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (units[unitIndex] === 'GB') {
    size = size.toFixed(1);
  } else {
    size = Math.round(size);
  }

  return `${size} ${units[unitIndex]}`;
};

export const formatLinksForFile = (obj, token) => {
  const extension = obj?.filename?.split('.')?.pop();
  let href = '';
  const removeForceDownload = (url) =>
    url.replace(/([?&])forcedownload=1(&|$)/, '$1').replace(/&$/, '');
  const tokenParam = `${obj.fileurl.includes('?') ? '&' : '?'}token=${btoa(
    token
  )}`;

  if (extensions.applicationExtensions.includes(extension)) {
    href = removeForceDownload(`${obj.fileurl}&token=${token}`);
  } else if (extensions.imageExtensions.includes(extension)) {
    href = removeForceDownload(
      `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(tokenParam)}`
    );
  } else if (extensions.audioExtensions.includes(extension)) {
    href = removeForceDownload(
      `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(tokenParam)}`
    );
  } else if (extensions.videoExtensions.includes(extension)) {
    href = removeForceDownload(
      `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(tokenParam)}`
    );
  } else if (extensions.textExtensions.includes(extension)) {
    href = removeForceDownload(
      `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${tokenParam}`
    );
  }
  return href;
};

export const formatLinksForFolder = (obj, token) => {
  const extension = obj?.filename?.split('.')?.pop();
  let href = '';
  const tokenParam = `${obj.fileurl.includes('?') ? '&' : '?'}token=${btoa(
    token
  )}`;
  if (extensions.applicationExtensions.includes(extension)) {
    href = `${obj.fileurl}&token=${token}`;
  } else if (extensions.imageExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(
      tokenParam
    )}`;
  } else if (extensions.audioExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(
      tokenParam
    )}`;
  } else if (extensions.videoExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${btoa(
      tokenParam
    )}`;
  } else if (extensions.textExtensions.includes(extension)) {
    href = `${obj.fileurl.replace(/\/webservice\//, '/')}&token=${tokenParam}`;
  }
  return href;
};
const Nav = [
  {
    link: ROUTES.HOME,
    symbol: 'home',
    title: 'Home',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.Sidebar_Home,
    isHidden: false
  },
  {
    link: ROUTES.PROGRAM_TIMELINE,
    symbol: 'local_library',
    title: 'Classoom',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.sidebar_classroom,
    isHidden: true
  },
  {
    link: ROUTES.PROGRESS_HUB,
    symbol: 'bar_chart_4_bars',
    title: 'Progress',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.sidebar_progress,
    isHidden: true
  },
  {
    link: ROUTES.SKILL_REPORT,
    symbol: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <mask
          id='mask0_25148_9160'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='24'
          height='24'
        >
          <rect
            x='0.5'
            y='0.5'
            width='23'
            height='23'
            fill='#D9D9D9'
            stroke='#D3D3D3'
          />
        </mask>
        <g mask='url(#mask0_25148_9160)'>
          <path
            d='M10.8301 2.43131C11.1707 2.18701 11.58 2.05554 12 2.05554C12.42 2.05554 12.8293 2.18701 13.1699 2.43131L21.1692 8.13836C21.5143 8.38559 21.7711 8.7358 21.9024 9.1381C22.0337 9.5404 22.0325 9.97384 21.8991 10.3754L18.8594 19.5823C18.7269 19.9791 18.4721 20.3243 18.131 20.5689C17.7899 20.8134 17.38 20.9448 16.9595 20.9444H7.04045C6.61998 20.9448 6.21007 20.8134 5.86901 20.5689C5.52795 20.3243 5.27308 19.9791 5.14063 19.5823L2.10091 10.3754C1.9675 9.97384 1.96635 9.5404 2.09761 9.1381C2.22888 8.7358 2.48573 8.38559 2.83084 8.13836L10.8301 2.43131Z'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='11.8611'
            y1='2.16663'
            x2='11.8611'
            y2='12.1666'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.833333'
          />
          <line
            x1='2.16922'
            y1='8.34141'
            x2='12.1692'
            y2='12.7859'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.833333'
          />
          <line
            x1='22.1318'
            y1='9.11745'
            x2='12.1318'
            y2='12.4508'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.833333'
          />
          <line
            x1='18.6874'
            y1='20.2755'
            x2='11.6874'
            y2='12.331'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.833333'
          />
          <line
            x1='5.01696'
            y1='20.6733'
            x2='11.6836'
            y2='12.8955'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.833333'
          />
          <circle
            cx='12'
            cy='9.27774'
            r='0.555556'
            fill='var(--tertiary-text-color)'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.11111'
          />
          <circle
            cx='7.00003'
            cy='10.3889'
            r='0.555556'
            fill='var(--tertiary-text-color)'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.11111'
          />
          <circle
            cx='16.4444'
            cy='10.9445'
            r='0.555556'
            fill='var(--tertiary-text-color)'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.11111'
          />
          <circle
            cx='9.22219'
            cy='15.3889'
            r='0.555556'
            fill='var(--tertiary-text-color)'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.11111'
          />
          <circle
            cx='15.3333'
            cy='16.5'
            r='0.555556'
            fill='var(--tertiary-text-color)'
            stroke='var(--tertiary-text-color)'
            strokeWidth='1.11111'
          />
          <path
            d='M11.7826 9.43254C11.5959 9.43254 11.7826 9.43254 11.4801 9.43251L6.81372 10.5602L8.95141 15.3836C9.01027 15.547 9.12355 15.6891 9.27513 15.7898C9.42671 15.8905 9.60889 15.9446 9.79577 15.9444L15.3154 17.0556C15.5022 17.0557 15.6844 17.0016 15.836 16.9009C15.9876 16.8002 15.7771 16.6634 15.836 16.5L16.3996 11.5925C16.4589 11.4271 16.4594 11.2487 16.4011 11.083C16.3427 10.9174 16.2286 10.7732 16.0752 10.6714L12.098 9.43251L11.7826 9.43254Z'
            stroke='var(--tertiary-text-color)'
            strokeWidth='0.555556'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    ),
    filledSymbol: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <mask
          id='mask0_25148_9160'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='24'
          height='24'
        >
          <rect
            x='0.5'
            y='0.5'
            width='23'
            height='23'
            fill='#D9D9D9'
            stroke='#D3D3D3'
          />
        </mask>
        <g mask='url(#mask0_25148_9160)'>
          <path
            d='M10.8301 2.43131C11.1707 2.18701 11.58 2.05554 12 2.05554C12.42 2.05554 12.8293 2.18701 13.1699 2.43131L21.1692 8.13836C21.5143 8.38559 21.7711 8.7358 21.9024 9.1381C22.0337 9.5404 22.0325 9.97384 21.8991 10.3754L18.8594 19.5823C18.7269 19.9791 18.4721 20.3243 18.131 20.5689C17.7899 20.8134 17.38 20.9448 16.9595 20.9444H7.04045C6.61998 20.9448 6.21007 20.8134 5.86901 20.5689C5.52795 20.3243 5.27308 19.9791 5.14063 19.5823L2.10091 10.3754C1.9675 9.97384 1.96635 9.5404 2.09761 9.1381C2.22888 8.7358 2.48573 8.38559 2.83084 8.13836L10.8301 2.43131Z'
            stroke='#5095FC'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='11.8611'
            y1='2.16663'
            x2='11.8611'
            y2='12.1666'
            stroke='#5095FC'
            strokeWidth='0.833333'
          />
          <line
            x1='2.16922'
            y1='8.34141'
            x2='12.1692'
            y2='12.7859'
            stroke='#5095FC'
            strokeWidth='0.833333'
          />
          <line
            x1='22.1318'
            y1='9.11745'
            x2='12.1318'
            y2='12.4508'
            stroke='#5095FC'
            strokeWidth='0.833333'
          />
          <line
            x1='18.6874'
            y1='20.2755'
            x2='11.6874'
            y2='12.331'
            stroke='#5095FC'
            strokeWidth='0.833333'
          />
          <line
            x1='5.01696'
            y1='20.6733'
            x2='11.6836'
            y2='12.8955'
            stroke='#5095FC'
            strokeWidth='0.833333'
          />
          <circle
            cx='12'
            cy='9.27774'
            r='0.555556'
            fill='#5095FC'
            stroke='#5095FC'
            strokeWidth='1.11111'
          />
          <circle
            cx='7.00003'
            cy='10.3889'
            r='0.555556'
            fill='#5095FC'
            stroke='#5095FC'
            strokeWidth='1.11111'
          />
          <circle
            cx='16.4444'
            cy='10.9445'
            r='0.555556'
            fill='#5095FC'
            stroke='#5095FC'
            strokeWidth='1.11111'
          />
          <circle
            cx='9.22219'
            cy='15.3889'
            r='0.555556'
            fill='#5095FC'
            stroke='#5095FC'
            strokeWidth='1.11111'
          />
          <circle
            cx='15.3333'
            cy='16.5'
            r='0.555556'
            fill='#5095FC'
            stroke='#5095FC'
            strokeWidth='1.11111'
          />
          <path
            d='M11.7826 9.43254C11.5959 9.43254 11.7826 9.43254 11.4801 9.43251L6.81372 10.5602L8.95141 15.3836C9.01027 15.547 9.12355 15.6891 9.27513 15.7898C9.42671 15.8905 9.60889 15.9446 9.79577 15.9444L15.3154 17.0556C15.5022 17.0557 15.6844 17.0016 15.836 16.9009C15.9876 16.8002 15.7771 16.6634 15.836 16.5L16.3996 11.5925C16.4589 11.4271 16.4594 11.2487 16.4011 11.083C16.3427 10.9174 16.2286 10.7732 16.0752 10.6714L12.098 9.43251L11.7826 9.43254Z'
            stroke='#5095FC'
            strokeWidth='0.555556'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    ),
    title: 'Skill Sync',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.sidebar_progress,
    isHidden: true
  },

  {
    link: ROUTES.LEARNING_HUB,
    symbol: 'newsmode',
    title: 'Resources',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.Sidebar_Resources,
    isHidden: false
  },
  {
    link: `${backendBaseUrl}/remote.php?token=${
      localStorage.getItem('token')
        ? btoa(localStorage.getItem('token'))
        : 'tokeninvalid'
    }&redirect=${backendBaseUrl}/simplesaml/saml2/idp/SSOService.php?spentityid=career-portal.herovired.com/saml-sp-issuer&RelayState=https://career-portal.herovired.com/dashboard`,
    symbol: 'business_center',
    title: 'Careers',
    external: true,
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.Sidebar_Careers,
    isHidden: true
  },
  {
    link: ROUTES.REFER_N_EARN,
    symbol: (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10H9C8.4 10 7.9 10.2 7.6 10.6L2 16M6 20L7.6 18.6C7.9 18.2 8.4 18 9 18H13C14.1 18 15.1 17.6 15.8 16.8L20.4 12.4C20.7859 12.0353 21.0111 11.5323 21.0261 11.0016C21.0411 10.4708 20.8447 9.95589 20.48 9.57C20.1153 9.18411 19.6123 8.95889 19.0816 8.94389C18.5508 8.92888 18.0359 9.12533 17.65 9.49L13.45 13.39M1 15L7 21M17.9 8C17.9 9.60163 16.6016 10.9 15 10.9C13.3984 10.9 12.1 9.60163 12.1 8C12.1 6.39837 13.3984 5.1 15 5.1C16.6016 5.1 17.9 6.39837 17.9 8ZM8 4C8 5.65685 6.65685 7 5 7C3.34315 7 2 5.65685 2 4C2 2.34315 3.34315 1 5 1C6.65685 1 8 2.34315 8 4Z'
          stroke='var(--secondary-text-color)'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    ),
    filledSymbol: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <g clip-path='url(#clip0_22460_5889)'>
          <path
            d='M10.8867 15.3H13.0837C13.6664 15.3 14.2253 15.0682 14.6373 14.6556C15.0493 14.2431 15.2808 13.6835 15.2808 13.1C15.2808 12.5165 15.0493 11.9569 14.6373 11.5444C14.2253 11.1318 13.6664 10.9 13.0837 10.9H9.78817C9.12906 10.9 8.5798 11.12 8.25024 11.56L2.09852 17.5M6.49261 21.9L8.25024 20.36C8.5798 19.92 9.12906 19.7 9.78817 19.7H14.1823C15.3906 19.7 16.4892 19.26 17.2581 18.38L22.3113 13.54C22.7352 13.1389 22.9826 12.5855 22.9991 12.0017C23.0156 11.4179 22.7998 10.8515 22.3992 10.427C21.9986 10.0025 21.446 9.75478 20.863 9.73828C20.28 9.72177 19.7143 9.93786 19.2904 10.339L14.6766 14.629M1 16.4L7.59113 23M19.565 8.7C19.565 10.4618 18.1387 11.89 16.3793 11.89C14.6199 11.89 13.1936 10.4618 13.1936 8.7C13.1936 6.93821 14.6199 5.51 16.3793 5.51C18.1387 5.51 19.565 6.93821 19.565 8.7ZM8.68965 4.3C8.68965 6.12254 7.21418 7.6 5.39409 7.6C3.574 7.6 2.09852 6.12254 2.09852 4.3C2.09852 2.47746 3.574 1 5.39409 1C7.21418 1 8.68965 2.47746 8.68965 4.3ZM8.68965 4.3C8.68965 6.12254 7.21418 7.6 5.39409 7.6C3.574 7.6 2.09852 6.12254 2.09852 4.3C2.09852 2.47746 3.574 1 5.39409 1C7.21418 1 8.68965 2.47746 8.68965 4.3Z'
            stroke='#5095FC'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_22460_5889'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Refer & Earn',
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.sidebar_refer_and_earn,
    isHidden: false
  },
  {
    link: `${backendBaseUrl}/remote.php?token=${
      localStorage.getItem('token')
        ? btoa(localStorage.getItem('token'))
        : 'tokeninvalid'
    }&redirect=http://bit.ly/3ExK5Ub`,
    symbol: 'confirmation_number',
    title: 'Ticket',
    external: true,
    cleverTapKey: HOME_SECTIONS.Sidebar.activity.Sidebar_Raise_A_Ticket,
    isHidden: false
  }
];

export const customToast = (
  headerText = '',
  description = '',
  className = '',
  autoClose = 3000
) => {
  toast.success(
    <SuccessToastMessage
      headerText={headerText}
      description={description}
      className={className}
    />,
    {
      className: 'Toastify__toast--show',
      onOpen: () => {
        setTimeout(() => {
          document
            .querySelector('.Toastify__toast--show')
            .classList.add('Toastify__toast--hide');
        }, autoClose);
      }
    }
  );
};

export const canPerformAction = (
  LAST_EVENT_KEY = 'temp',
  DURATION = 7200000
) => {
  const now = Date.now();
  const lastEventTime = localStorage.getItem(LAST_EVENT_KEY);

  if (!lastEventTime || now - parseInt(lastEventTime, 10) >= DURATION) {
    localStorage.setItem(LAST_EVENT_KEY, now.toString());
    return true;
  } else {
    const remainingTime = DURATION - (now - parseInt(lastEventTime, 10));
    return false;
  }
};

export const getYoutubeEmbedUrl = (url) => {
  if (!url) return '';
  if (
    url?.includes('youtube.com/embed/') ||
    url?.includes('youtube-nocookie.com/embed/')
  ) {
    return url;
  }
  const videoIdMatch = url?.match(
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
  );
  const videoId = videoIdMatch ? videoIdMatch?.[1] : null;

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }
  return url;
};

function checkExistingKey(id) {
  let storage = localStorage.getItem('showedEnrolled');
  if (storage == '0' || storage == '1') {
    localStorage.removeItem('showedEnrolled');
    return null;
  }
  if (storage) {
    const temp = JSON.parse(storage || '[]');
    const value = temp?.findIndex((val) => {
      return val?.parentId == id || val?.childId == id;
    });
    if (value >= 0) {
      return {
        index: value,
        data: temp[value],
        storage: temp
      };
    } else {
      return null;
    }
  }
  return null;
}

export {
  carreerSession,
  data,
  constantLogin,
  constantSignUp,
  formatDate,
  DATE_WEEK_STRING_FORMAT,
  courseStatus,
  getUrlQueryParams,
  activityIcon,
  searchPopover,
  CURRENT_INDUSTRY,
  PREFERRED_CITY_LIST,
  SOCIAL_PROFILE,
  SKILLS_PLACEHOLDER,
  UG_COURSE_LIST,
  PG_COURSE_LIST,
  handleLastDecimalChange,
  getDaysDiff,
  courseStatusVal,
  DEFAULT_SOCIAL_PROFILE_SELECT_OPTION,
  parseQueryParams,
  htmlDataParser,
  parseInteger,
  ACCESS_TYPE,
  PROGRESS_TAB,
  getIp,
  resourceSidebarNavItem,
  PER_PAGE,
  SUPPORTED_PAGE_ACTIVITY,
  DATE_WEEKTIME_STRING_FORMAT,
  QUIZ_TYPE,
  progressSectionCardDetails,
  QUIZ_ANSWER_STATUS,
  getFileType,
  calculatePercentage,
  Resume_Approval_Status,
  getStatus,
  LiveIcon,
  handleRedirection,
  handleStringToNumber,
  extractProgramIdFromUrl,
  cleanUrl,
  getUrlReplace,
  Nav,
  checkExistingKey
};
