import React from 'react';
import './successToast.scss';
import SuccessIllustration from '../../asset/images/success-illustration.svg';
import FailureIllustration from '../../asset/images/failure-illustration.svg';
import circleIllustration from '../../asset/images/circleIllustration.svg';

function SuccessToast({
  image = null,
  headerText = null,
  description = null,
  className = null
}) {
  const getTimePlus30MinutesIST = () => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 30);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istTime = new Date(currentDate.getTime() + istOffset);
    const hours = istTime.getUTCHours();
    const minutes = istTime.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  const getIllustration = () => {
    if (image) return image;
    switch (className) {
      case 'error':
        return FailureIllustration;
      case 'warning':
        return circleIllustration; // Return the warning illustration
      case 'success':
      default:
        return SuccessIllustration;
    }
  };

  return (
    <div className={`success-toast ${className}`}>
      <img src={getIllustration()} className='success-toast__illustration' />
      <div className='success-toast__content-container'>
        <div
          className={`success-toast__header ${className}`}
          style={{
            background: 'none'
          }}
        >
          {headerText || 'Post Created'}
        </div>
        {description && (
          <div className='success-toast__content'>
            {description ||
              `You have till ${getTimePlus30MinutesIST()} to edit your post.`}
          </div>
        )}
      </div>
    </div>
  );
}

export default SuccessToast;
