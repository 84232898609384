import React from 'react';
import Modal from 'react-modal';
import './studyMaterialModal.scss';
import TabHeader from '../TabSelector/TabHeader';

function StudyMaterialModal({ isVisible, onClose, studyMaterials }) {
  return (
    <>
      <Modal
        isOpen={isVisible}
        onRequestClose={onClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '5',
            overflowY: 'scroll'
          },
          content: {
            padding: '0',
            width: '500px',
            height: 'max-content',
            margin: 'auto',
            border: 'none',
            borderRadius: '20px'
          }
        }}
      >
        <div className='modal-header'>
          <TabHeader title={'Study Material'} />
          <button className='close-button' onClick={onClose}>
            <span class='material-symbols-outlined'>close</span>
          </button>
        </div>
        <ul className='study-material-list'>
          {studyMaterials.map((material, index) => (
            <li key={index} className='study-material-item'>
              <span className='study-material-list__course-name'>{material.courseName}</span>
              <a href={material.link} target='_blank' rel='noopener noreferrer'>
                View Resource
              </a>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
}

export default StudyMaterialModal;
