import React from 'react';
import './customSkillBarChart.scss';

const CustomSkillBarChart = ({
  skill,
  primaryPercentage,
  secondaryPercentage,
  dualBar,
  primaryColor = '#CC67FF',
  secondaryColor = '#FA9537'
}) => {
  return (
    <div className='bar-chart'>
      <span className='skill'>{skill}</span>
      <div className={`bar-container ${dualBar ? 'dual-bar' : ''}`}>
        <div
          className='bar primary-bar'
          style={{ width: `${primaryPercentage}%`, backgroundColor: primaryColor }}
        >
          <span className='bar-percentage'>{primaryPercentage}%</span>
        </div>
        {dualBar && (
          <div
            className='bar secondary-bar'
            style={{ width: `${secondaryPercentage}%`, backgroundColor: secondaryColor }}
          >
            <span className='bar-percentage'>{secondaryPercentage}%</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSkillBarChart;
