import React from 'react';
import './statusBox.scss';

const StatusBox = ({
  label,
  percentage,
  isActive = false,
  iconFill = false,
  hasBorder = true,
  textColor = '#000',
  iconColor='',
  onClick,
}) => {
const finalIconColor = iconFill ? textColor : iconColor 
  return (
    <div
      className={`status-box ${isActive ? 'active' : ''} ${hasBorder ? 'with-border' : 'no-border'}`}
      onClick={onClick}
    >
        <div className='status-content-tab'>
        {/* <span
          className={`material-symbols-outlined icon ${isActive ? 'filled' : 'outlined'}`}
          style={{ color: finalIconColor }}
        >
          hexagon
        </span> */}
        <span className="label">{label}</span>
        </div>
        {/* <div className="percentage" style={{ color: textColor }}>
          {percentage}%
        </div> */}
      </div>
  );
};

export default StatusBox;
