export const CLEVER_TAP_EVENTS = {
  Login_Attempt: 'Login_Attempt',
  LogIn: 'LogIn',
  HOME_ACTIVITY: 'Home_Activity',
  Resources: 'Resources',
  Bookmarked: 'Bookmarked',
  Resources_Detail_Page: 'Resources_Detail _Page',
  Profile: 'Profile',
  Notifications: 'Notifications',
  Progress: 'Progress',
  Progress_Details: 'Progress_Details',
  Grades_details_activity: 'Grades_details_activity',
  Attendance_details_activity: 'Attendance_details_activity',
  Quiz_Review: 'Quiz_Review',
  Class: 'Class',
  Session: 'Session',
  Program_Timeline: 'Classroom',
  Program_Timeline_Activity: 'classroom_activity',
  Quizzes: 'Quizzes',
  Assignments: 'Assignments',
  Search: 'Search',
  Search_Filters_Applied: 'Search_Filters_Applied',
  Search_Result_Clicked: 'Search_Result_Clicked',
  Course_Timeline_Activity: 'Course_Timeline_Activity',
  Activity_Started: 'Activity_Started',
  Activity_Submitted: 'Activity_Submitted',
  Activity_Finish: 'Activity_Finish',
  Page_Activity: 'Page_Activity',
  App_Benefits_Action: 'App_Benefits_Action',
  Feedback_Action: 'Feedback_Action',
  Feedback_Submitted: 'Feedback_Submitted',
  Elective: 'Elective',
  Refer_And_Earn: 'Refer_And_Earn',
  Attendance_details_activity: 'Attendance_details_activity',
  Dark_Mode_Toggle: 'Dark_Mode_Toggle',
  Active_Vlearn_Theme: 'Active_Vlearn_Theme',
};
export const defaultCleverTapValue = 'NA';
export const HOME_SECTIONS = {
  MastheadBanner: {
    section: 'Masthead_Banner',
    activity: {
      Select_Elective: 'Select_Elective',
      Refer_and_Earn: 'Refer_and_Earn'
    }
  },
  ReminderCards: {
    section: 'Reminder_Cards',
    activity: {
      assign: 'Reminder_Assignment_Clicked',
      zoom: 'Reminder_Session_Clicked',
      zoomduplicate: 'Reminder_Session_Clicked',
      quiz: 'Reminder_Quiz_Clicked',
      feedback: 'Reminder_Feedback_Clicked'
    }
  },
  ProgressCard: {
    section: 'Progress_Card'
  },
  Sessions: {
    section: 'Sessions',
    activity: {
      Scheduled: 'Sessions_Scheduled',
      'Past Sessions': 'Sessions_Past_Meeting'
    }
  },
  RecentCoursesCard: {
    section: 'Recent_Courses_Card'
  },
  Calendar: {
    section: 'Calendar',
    activity: {
      Calendar_Event_Class: 'Calendar_Event_Class',
      Calendar_Event_Assignment: 'Calendar_Event_Assignment',
      Calendar_Event_Quiz: 'Calendar_Event_Quiz'
    }
  },
  Assignments: {
    section: 'Assignments',
    activity: {
      clicked: 'Assignment_clicked',
      Submitted: 'Assignments_Submitted',
      Missed: 'Assignments_Missed',
      Graded: 'Assignments_Graded',
      Scheduled: 'Assignments_Scheduled',
      Due: 'Assignments_Due'
    }
  },
  Quizzes: {
    section: 'Quizzes',
    activity: {
      clicked: 'quiz_clicked',
      Missed: 'quiz_missed',
      Attempted: 'quiz_attempted',
      Graded: 'quiz_graded',
      Scheduled: 'quiz_scheduled',
      Due: 'quiz_due'
    }
  },
  Program: {
    section: 'Program',
    activity: {
      Continue_Learning: 'Continue_Learning',
      Expand_Courses: 'Expand_Courses',
      Collapse_Courses: 'Collapse_Courses',
      More_Courses: 'More_Courses'
    }
  },
  Classes: {
    section: 'Classes',
    activity: {
      Classes_View_All: 'Classes_View_All',
      cancelled: 'Classes_Cancelled',
      scheduled: 'Classes_Scheduled',
      ongoing: 'Classes_Ongoing',
      'past meeting': 'Classes_Past_Meeting'
    }
  },
  Sidebar: {
    section: 'Sidebar',
    activity: {
      sidebar_classroom: 'sidebar_classroom',
      Sidebar_Resources: 'Sidebar_Resources',
      Sidebar_Home: 'Sidebar_Home',
      Sidebar_Raise_A_Ticket: 'Sidebar_Raise_A_Ticket',
      sidebar_progress: 'sidebar_progress',
      Sidebar_Home: 'Sidebar_Home',
      Sidebar_Careers: 'Sidebar_Careers',
      sidebar_refer_and_earn: 'sidebar_refer_and_earn'
      // Refer_Earn_Learn_More: 'Refer_Earn_Learn_More',
      // Refer_Earn_View_Referrals: 'Refer_Earn_View_Referrals'
    }
  },
  Resources: {
    section: 'Resources',
    activity: {
      'All Resources': 'All Resources',
      Resource_Item_Clicked: 'Resource_Item_Clicked',
      Resources_Item: 'Resources_Item',
      Resource_Category_Clicked: 'Resource_Category_Clicked',
      Resources_ViewAll: 'Resources_ViewAll'
    }
  },
  Progress: {
    section: 'Progress',
  },
  Learn: { section: 'Learn' },
  Navigation: {
    section: 'Nav_Bar',
    activity: { Notification_Bell: 'Notification_Bell', Profile: 'Profile' }
  },
  Refer_Earn_Card: { section: 'Refer_Earn_Card' }
};

export const Page_Activity = {
  PAGE: {
    Home: 'Home',
    Learning_Hub: 'Resources',
    Program_Timeline: 'Classroom',
    Course_Overview: 'Course_Overview',
    Course_Contents: 'Course_Contents',
    Course_Activity: 'Course_Activity',
    Resources: 'Resources',
    Resource_Detail: 'Resource_Detail',
    App_Benefits: 'App_Benefits',
    REFER: 'Refer_and_Earn'
  },
  Activity_Name: {
    Quiz: 'Quiz',
    Assignment: 'Assignment',
    URL: 'URL',
    File: 'File',
    Folder: 'Folder',
    Zoom: 'Zoom',
    Zoom_Duplicate: 'Zoom_Duplicate',
    DoSelect_Quiz: 'DoSelect_Quiz',
    Feedback: 'Feedback'
  }
};
