import React, { useState } from 'react';
import './skillsCard.scss';
import TabHeader from '../TabSelector/TabHeader';
import StudyMaterialModal from '../StudyMaterialModal/StudyMaterialModal';

const studyMaterialsData = {
  attempt1: [
    { courseName: "Course 1 Name", link: "#" },
    { courseName: "Course 2 Name", link: "#" },
    { courseName: "Course 3 Name", link: "#" },
    { courseName: "Course 4 Name", link: "#" },
    { courseName: "Course 5 Name", link: "#" },
    { courseName: "Course 6 Name", link: "#" },
    { courseName: "Course 7 Name", link: "#" },
    { courseName: "Course 8 Name", link: "#" },
    { courseName: "Course 9 Name", link: "#" },
    { courseName: "Course 10 Name", link: "#" },
  ],
  attempt2: [
    { courseName: "Course A Name", link: "#" },
    { courseName: "Course B Name", link: "#" },
    { courseName: "Course C Name", link: "#" },
    { courseName: "Course D Name", link: "#" },
    { courseName: "Course E Name", link: "#" },
    { courseName: "Course F Name", link: "#" },
    { courseName: "Course G Name", link: "#" },
    { courseName: "Course H Name", link: "#" },
  ]
};

const skillData = {
  attempt1: [
      {
          title: 'Skill 1',
          score: '12',
          total: '15',
          gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
          points: [
              'Understanding customer needs is critical for product success.',
              'Identifying customer requirements to define the target market.',
          ],
      },
      {
        title: 'Skill 1',
        score: '12',
        total: '15',
        gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
        points: [
            'Understanding customer needs is critical for product success.',
            'Identifying customer requirements to define the target market.',
        ],
    },
    {
      title: 'Skill 1',
      score: '12',
      total: '15',
      gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
      points: [
          'Understanding customer needs is critical for product success.',
          'Identifying customer requirements to define the target market.',
      ],
  },
  {
    title: 'Skill 1',
    score: '12',
    total: '15',
    gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
    points: [
        'Understanding customer needs is critical for product success.',
        'Identifying customer requirements to define the target market.',
    ],
},
{
  title: 'Skill 1',
  score: '12',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Understanding customer needs is critical for product success.',
      'Identifying customer requirements to define the target market.',
  ],
},
      // other skills for Attempt 1
  ],
  attempt2: [
      {
          title: 'Skill A',
          score: '10',
          total: '15',
          gradient: 'linear-gradient(to right, #fbc2eb, #a6c1ee)',
          points: [
              'Managing team dynamics for efficient collaboration.',
              'Driving team goals towards successful project delivery.',
          ],
      },
      {
        title: 'Skill A',
        score: '3',
        total: '15',
        gradient: 'linear-gradient(to right, #fbc2eb, #a6c1ee)',
        points: [
            'Managing team dynamics for efficient collaboration.',
            'Driving team goals towards successful project delivery.',
        ],
    }, {
      title: 'Skill A',
      score: '10',
      total: '15',
      gradient: 'linear-gradient(to right, #fbc2eb, #a6c1ee)',
      points: [
          'Managing team dynamics for efficient collaboration.',
          'Driving team goals towards successful project delivery.',
      ],
  }, {
    title: 'Skill A',
    score: '4',
    total: '15',
    gradient: 'linear-gradient(to right, #fbc2eb, #a6c1ee)',
    points: [
        'Managing team dynamics for efficient collaboration.',
        'Driving team goals towards successful project delivery.',
    ],
},
      // other skills for Attempt 2
  ]
};
function SkillsCard({ isAttempt1 }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const currentSkills = isAttempt1 ? skillData.attempt1 : skillData.attempt2;
  const attemptKey = isAttempt1 ? 'attempt1' : 'attempt2';
  // const handleViewMaterialClick = () => {
  //   setModalContent(studyMaterialsData[attemptKey]);
  //   setModalVisible(true);
  // };

  const linksPerSkill = Math.ceil(
    studyMaterialsData[attemptKey].length / currentSkills.length
  );

  const handleViewMaterialClick = (skillIndex) => {
    const start = skillIndex * linksPerSkill;
    const end = start + linksPerSkill;
    const skillLinks = studyMaterialsData[attemptKey].slice(start, end);
    setModalContent(skillLinks);
    setModalVisible(true);
  };

  const getGradient = (percentage) => {
    if (percentage >= 80) {
      return 'var(--prt-skill-test-good)';
    } else if (percentage >= 60) {
      return 'var(--prt-skill-test-average)';
    } else {
      return 'var(--prt-skill-test-bad)';
    }
  };

  const closeModal = () => setModalVisible(false);
  return (
   <div className="skill-card">
        {currentSkills.map((skill, index) => {
        const percentage = (parseInt(skill.score) / parseInt(skill.total)) * 100;
        const gradient = getGradient(percentage);
        return (
          <div key={index} className="skill-card-content">
            <TabHeader
              title={skill.title}
              background={gradient}
              cta={
                <span>
                  <span className="cta-numerator">{skill.score}</span> /
                  <span className="cta-denominator">{skill.total}</span>
                </span>
              }
            />
            <div className="skill-points">
              <ul>
                {skill.points.map((point, idx) => (
                  <div key={idx} className="skill-content">
                    {point}
                  </div>
                ))}
              </ul>
              <div
                className="skill-points__title"
                onClick={() => handleViewMaterialClick(index)}
              >
                View Study Material
              </div>
            </div>
          </div>
        );
      })}
       <StudyMaterialModal
        isVisible={isModalVisible}
        onClose={closeModal}
        studyMaterials={modalContent}
        // title={`Study Material for ${isAttempt1 ? "Attempt 1" : "Attempt 2"}`}
      />
   </div>
  )
}

export default SkillsCard