import React from 'react';
import './CircleProgress.scss';

const CircleProgress = ({ data }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const gap = 14;

  // Total number of arcs (gaps will be placed between them)
  const numArcs = data.length;

  // Available circumference for arcs after accounting for gaps
  const totalArcCircumference = circumference - numArcs * gap;

  // Get the stroke-dasharray for the filled arc
  const getStrokeDashArray = (numerator, denominator) => {
    const fullArcLength = (denominator / 100) * totalArcCircumference;
    const filledArcLength = (numerator / denominator) * fullArcLength;
    return `${filledArcLength} ${circumference - filledArcLength}`;
  };

  // Calculate the offset for each arc, adjusted for gaps
  const getStrokeDashOffset = (index) => {
    return data
      .slice(0, index)
      .reduce(
        (acc, arc) =>
          acc + (arc.denominator / 100) * totalArcCircumference + gap,
        0
      );
  };

  return (
    <div className='circle-progress'>
      <svg viewBox='0 0 120 120' width={'153px'} height={'153px'}>
        {data.map((arc, index) => {
          const offset = getStrokeDashOffset(index);

          return (
            <>
              {/* Background arc */}
              <circle
                key={`bg-${index}`}
                className='arc-bg'
                cx='60'
                cy='60'
                r={radius}
                stroke={arc.backgroundColor}
                strokeDasharray={`${
                  (arc.denominator / 100) * totalArcCircumference
                } ${circumference}`}
                strokeDashoffset={-offset}
                strokeWidth='12'
                fill='none'
                strokeLinecap='round'
              />
              {/* Filled arc */}
              <circle
                key={`fill-${index}`}
                className='arc-fill'
                cx='60'
                cy='60'
                r={radius}
                stroke={arc.color}
                strokeDasharray={getStrokeDashArray(
                  arc.numerator,
                  arc.denominator
                )}
                strokeDashoffset={-offset}
                strokeWidth='12'
                fill='none'
                strokeLinecap='round'
              />
            </>
          );
        })}
      </svg>
      <div className='circle-progress__label-box'>
        <div className='circle-progress__label'>69%</div>
        <div className='circle-progress__sub-label'>overall</div>
      </div>
    </div>
  );
};

export default CircleProgress;
